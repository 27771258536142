// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        //console.log("HMR")
    });
}

import '../css/main.css'

import './controllers'

import 'lazysizes';

document.addEventListener("DOMContentLoaded", function(){

    window.addEventListener('resize', function () {
        resize();
    });
    resize();


    function resize() {

    }

    let lazys = document.querySelectorAll(".lazy-start");
    lazys.forEach(function(entry) {
        entry.classList.add("lazyload")
    });

    const tham = document.querySelector(".burger");

    tham.addEventListener('click', () => {
        tham.querySelector(".tham").classList.toggle('tham-active');

        if(tham.querySelector(".tham").classList.contains("tham-active")) {
            lockpage()
        } else {
            unlockpage()
        }

    });

    function lockpage() {
        document.querySelector("html").classList.remove("scroll-smooth")
        document.querySelector("html").classList.add("locked")

        document.body.style.top = `-${window.scrollY}px`
        document.body.style.position = 'fixed'
        document.body.style.overflowY = 'scroll'
        document.body.style.width = '100%'
        document.querySelector(".mobile-nav").classList.remove("hidden")
        setTimeout(() => {
            document.querySelector(".mobile-nav").classList.remove("translate-x-full")
        },10)

    }

    function unlockpage() {
        const scrollY = document.body.style.top;
        document.body.style.position = '';
        document.body.style.top = '';
        document.body.style.overflowY = ''
        document.body.style.width = ''

        window.scrollTo(0, parseInt(scrollY || '0') * -1);

        document.querySelector(".mobile-nav").classList.add("translate-x-full")

        setTimeout(() => {
            document.querySelector("html").classList.add("scroll-smooth")
            document.querySelector("html").classList.remove("locked")
        }, 20)
    }



});