import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    static targets = ['up'];
    static values = {
        lastScrollPosition: Number
    }

    connect() {
        window.addEventListener('scroll', this.handleScroll.bind(this));
        setTimeout(() => {
            this.upTarget.classList.add("transition-transform")
        }, 10)
        setTimeout(() => {
            this.handleScroll()
        }, 500)
    }

    disconnect() {
        window.removeEventListener('scroll', this.handleScroll.bind(this));
    }

    handleScroll() {
        const scrollPosition = window.scrollY || window.pageYOffset;
        const viewportHeight = window.innerHeight;

        if (scrollPosition > viewportHeight / 2) {
            document.body.classList.add('scrolled-halfway');
            this.upTarget.classList.remove("translate-y-[120%]")
        } else {
            this.upTarget.classList.add("translate-y-[120%]")
            document.body.classList.remove('scrolled-halfway');
        }

        if(scrollPosition < this.lastScrollPositionValue) {
            document.body.classList.add('scrolled-up');
        } else {
            if(scrollPosition > this.lastScrollPositionValue+50) {
                document.body.classList.remove('scrolled-up');
            }
        }

        this.lastScrollPositionValue = scrollPosition
    }

    scrollUp() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Animiert das Scrollen
        });
    }
}